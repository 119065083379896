import { merge } from "highcharts";
import { error } from "utils/error";
import { setGlobal } from "utils/storage/global";
import { IApiConfigurations, IAuthConfig, IConfigFile } from "./schema";
import { Auth0Client } from '@auth0/auth0-spa-js';

export type AppEnvironment = typeof process.env.NODE_ENV | "staging";

class Config implements IConfig {
  constructor() {
    if (this.isLocal && this.isDev) {
      this.log.enabled = true;
      this.log.level = "debug";
    }
    setGlobal("config", this);
  }

  public async load() {
    let config = await loadConfig("config");

    if (this.isLocal) {
      config = await loadConfig("config.local", config);
    } else if (this.isStaging) {
      config = await loadConfig("config.staging");
    }

    Object.assign(this, config);
  }

  /**
   * Returns an Auth0Client instance with the current configuration
   * @returns Auth0Client
   * */
  public getAuthClient() {
    return new Auth0Client({
      domain: this.auth.domain,
      clientId: this.auth.clientId,
      authorizationParams: {
        audience: this.auth.audience,
        redirect_uri: window.location.origin,
      }
    });
  }


  public auth!: IAuthConfig;
  public api!: IApiConfigurations;
  public devApp = window.location.hash === "#wind";
  public readonly log = {
    enabled: false,
    level: "warn",
    types: {
      mem: false,
    },
  };

  public get environment(): AppEnvironment {
    // REACT_APP_ENV is an optional override used by preview branches
    // If it's not set, we use NODE_ENV
    const envOverride = process.env.REACT_APP_ENV as AppEnvironment | undefined;
    return envOverride || process.env.NODE_ENV;
  }

  public get isLocal() {
    return (
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
    );
  }
  public get isDev() {
    return !this.environment || this.environment === "development";
  }

  public get isStaging() {
    return this.environment === "staging";
  }
}

export interface IConfig extends IConfigFile {
  load(): Promise<void>;
  getAuthClient(): Auth0Client;
  isDev: boolean;
  isLocal: boolean;
  isStaging: boolean;
  environment: AppEnvironment;
  devApp: boolean;
  log: {
    enabled: boolean;
    level: string;
    types: Record<string, boolean | undefined>;
  };
}

async function loadConfig(file: string, base?: IConfigFile) {
  try {
    const resp = await fetch(`config/${file}.json?rnd=${Math.random()}`);
    const json = await resp.json();
    if (base) {
      return merge(base, IConfigFile.deepPartial().parse(json));
    } else {
      return IConfigFile.parse(json);
    }
  } catch (cause) {
    throw error("Config", "failed to parse config file", { cause });
  }
}

export const config: IConfig = (globalThis.__app.config = new Config());
export default config;
